/* CSS for KaPlan list row calendar and entries */

.ap-kaplanlist-wrapper {
    .frontendfilter-wrapper {
        margin-bottom: 20px;

        .dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover {
            background-color: $main-theme;
        }
    }

    .linkmore {
        margin-top: 10px;
    }
}

.ap-kaplanlistrow {

    color: $text-dark;
    font-size: 16px;
    margin-bottom: 25px;

    a.link {
        color: $text-dark;
    }

    a:hover {
        color: $main-theme-hover;
        text-decoration: none;
    }

    .entrydate-wrapper-xs {

        .inner {
            background-color: $main-theme;
            color: $text-light;
            margin-bottom: 4px;
               padding: 1px 15px;

            .day {
                float: left;
            }
            .date {
                font-weight: bold;
            }
        }
    }

    .entrydaydesc {
        color: $main-theme;
        font-weight: bold;
        margin-bottom: 4px;
    }

    .entrydata {
        clear: both;
        margin-bottom: 8px;
        padding: 2px 4px;
        background-color: $grey-lighter;
        border: 1px solid $grey-light;
        .entrymaintitle {
            font-weight: bold;
        }
        .entrymaintitle, .entryteaser {
            display: block;
        }
    }

    .entrydata:hover {
        border: 1px solid $grey;
        background-color: $white;
        transition: border 1s, background-color 1s, transform 1s;
    }

    .calendar-sheet {
        min-width: 90px;
        border: 1px solid $grey-light;
        text-align: center;
        background: $grey-lighter;

        &:before {
            border-bottom: 10px solid $main-theme;
            display: block;
            content: '';
            margin: 0 10%;
        }

        .day,
        .monthYear {
            padding: 0;
            font-size: 16px;
            font-weight: bolder;
            color: $grey;
        }

        h3 {
            color: $main-theme;
            font-weight: bolder;
            margin-top: 2px;
            margin-bottom: 2px;
            line-height: 24px;
            font-size: 18px;
        }
    }

    // Calendar sheets get a slightly different raise effect
    &.ap-raise-animation:hover .animated-box {
        box-shadow: 5px 5px 8px -1px $dropshadow;
    }
}

@media (max-width: $screen-xs-max) {
    .ap-kaplanlistrow {
        margin-bottom: 20px;

        .teaser-body {
             margin: 0 15px;
        }

        .entrydata {
            padding: 0;
            background-color: $white;
            border: none;
        }

        .entrydata:hover {
            border: none;
        }
    }
}
