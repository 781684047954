@if require-font('Open Sans') {

/* open-sans-300 - latin_latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: local('Open Sans Light'), local('OpenSans-Light'),
       url('../fonts/open-sans-v15-latin_latin-ext-300.woff2') format('woff2'),
       url('../fonts/open-sans-v15-latin_latin-ext-300.woff') format('woff');
}
/* open-sans-300italic - latin_latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-display: fallback;
  src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'),
       url('../fonts/open-sans-v15-latin_latin-ext-300italic.woff2') format('woff2'),
       url('../fonts/open-sans-v15-latin_latin-ext-300italic.woff') format('woff');
}
/* open-sans-regular - latin_latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: local('Open Sans'), local('OpenSans'),
       url('../fonts/open-sans-v15-latin_latin-ext-regular.woff2') format('woff2'),
       url('../fonts/open-sans-v15-latin_latin-ext-regular.woff') format('woff');
}
/* open-sans-italic - latin_latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
       url('../fonts/open-sans-v15-latin_latin-ext-italic.woff2') format('woff2'),
       url('../fonts/open-sans-v15-latin_latin-ext-italic.woff') format('woff');
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
       url('../fonts/open-sans-v15-latin_latin-ext-600.woff2') format('woff2'),
       url('../fonts/open-sans-v15-latin_latin-ext-600.woff') format('woff');
}
/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-display: fallback;
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
       url('../fonts/open-sans-v15-latin_latin-ext-600italic.woff2') format('woff2'),
       url('../fonts/open-sans-v15-latin_latin-ext-600italic.woff') format('woff');
}
/* open-sans-700 - latin_latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('../fonts/open-sans-v15-latin_latin-ext-700.woff2') format('woff2'),
       url('../fonts/open-sans-v15-latin_latin-ext-700.woff') format('woff');
}
/* open-sans-700italic - latin_latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  font-display: fallback;
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
       url('../fonts/open-sans-v15-latin_latin-ext-700italic.woff2') format('woff2'),
       url('../fonts/open-sans-v15-latin_latin-ext-700italic.woff') format('woff');
}

} // end @if
