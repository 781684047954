.ap-newsletter .emaillabel {
    float: left;
    width: 25%;
    display: inline-block;
}
.ap-newsletter .emailvalue {
    width: 75%;
    display: inline-block;
}
.ap-newsletter .nlsubject .emailvalue {
    font-weight: bold;
}
.ap-newsletter .list-group-item {
    clear: left;
}
.ap-newsletter .preview {
    width: 100%;
    height: 400px;
    border: 1px solid $grey-light;
    padding: 0;
    margin: 0;
}