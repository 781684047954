.ap-orgchart, .ap-department {
	margin-bottom: 20px;

	.orgchart-box {
		border: 1px solid $grey-light;
		margin-bottom: 20px;
		padding: 8px;
		font-size: 15px;

		.orgrow:first-child {
			border-top: none;
		}

        a:not(.btn) {
            color: $main-theme-complementary;

            &:hover {
                color: lighten($main-theme-complementary, 15%);
            }
        }

		.orgrow {
			border-top: 1px solid $grey-lighter;
			clear: both;
			padding-bottom: 26px;
			padding-top: 6px;

			.department {
				float: left;
				width: 60%;
				
				> a {
					font-weight: 600;
					/* text-shadow: 2px 2px 1px $grey; */
				}
			}

			.members {
				float: left;
				width: 40%;
			}

		}

		.lvl-2 .department {
			padding-left: 12px
		}
		.lvl-3 .department {
			padding-left: 24px;
		}
		.lvl-4 .department {
			padding-left: 36px;
		}
		.lvl-5 .department {
			padding-left: 48px;
		}
		.lvl-2 .members {
			width: calc(40% - 12px);
		}
		.lvl-3 .members {
			width: calc(40% - 24px);
		}
		.lvl-4 .members {
			width: calc(40% - 36px);
		}
		.lvl-5 .members {
			width: calc(40% - 48px);
		}

		@media (max-width: $screen-xs-max) {
		
			.orgrow .department,
			.orgrow .members {
				width: 100%;
			}
			
			.lvl-2 .members {
				padding-left: 12px
			}
			.lvl-3 .members {
				padding-left: 24px;
			}
			.lvl-4 .members {
				padding-left: 36px;
			}
			.lvl-5 .members {
				padding-left: 48px;
			}
		}
		
		ul {
			margin-bottom: 0;
			
			li.head {
				font-weight: 400;
			}
			li.member {
				font-size: 13px;
			}
		}
		
		.orgchart-person {
            font-size: 13px;
            margin-bottom: 8px;

            .phone-label, .mobile-label, .fax-label {
                float: left;
                width: 60px;
            }
	        .data {
	            float: left;
	            width: 60%;
	        }
	        .data .name {
	            font-size: 14px;
	            font-style: italic;
	        }
        } 
	
	}
	
	/* Image styles */
	.orgchart-image .easyzoom {
		border: 1px solid $grey-light;
	}
	
	.imgcaption {
		margin-bottom: 10px;
	}
	
	.pdflink {
		margin-bottom: 10px;
	}
}