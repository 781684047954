@if require-font('Prompt') {

/* prompt-300 - latin_latin-ext */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 300;
  font-display: fallback;
  src: local('Prompt Light'), local('Prompt-Light'),
       url('../fonts/prompt-v2-latin_latin-ext-300.woff2') format('woff2'),
       url('../fonts/prompt-v2-latin_latin-ext-300.woff') format('woff');
}
/* prompt-300italic - latin_latin-ext */
@font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 300;
  font-display: fallback;
  src: local('Prompt Light Italic'), local('Prompt-LightItalic'),
       url('../fonts/prompt-v2-latin_latin-ext-300italic.woff2') format('woff2'),
       url('../fonts/prompt-v2-latin_latin-ext-300italic.woff') format('woff');
}
/* prompt-regular - latin_latin-ext */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: local('Prompt'), local('Prompt-Regular'),
       url('../fonts/prompt-v2-latin_latin-ext-regular.woff2') format('woff2'),
       url('../fonts/prompt-v2-latin_latin-ext-regular.woff') format('woff');
}
/* prompt-italic - latin_latin-ext */
@font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 400;
  font-display: fallback;
  src: local('Prompt Italic'), local('Prompt-Italic'),
       url('../fonts/prompt-v2-latin_latin-ext-italic.woff2') format('woff2'),
       url('../fonts/prompt-v2-latin_latin-ext-italic.woff') format('woff');
}
/* prompt-500 - latin_latin-ext */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
  src: local('Prompt Medium'), local('Prompt-Medium'),
       url('../fonts/prompt-v2-latin_latin-ext-500.woff2') format('woff2'),
       url('../fonts/prompt-v2-latin_latin-ext-500.woff') format('woff');
}
/* prompt-500italic - latin_latin-ext */
@font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 500;
  font-display: fallback;
  src: local('Prompt Medium Italic'), local('Prompt-MediumItalic'),
       url('../fonts/prompt-v2-latin_latin-ext-500italic.woff2') format('woff2'),
       url('../fonts/prompt-v2-latin_latin-ext-500italic.woff') format('woff');
}
/* prompt-600 - latin_latin-ext */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: local('Prompt SemiBold'), local('Prompt-SemiBold'),
       url('../fonts/prompt-v2-latin_latin-ext-600.woff2') format('woff2'),
       url('../fonts/prompt-v2-latin_latin-ext-600.woff') format('woff');
}
/* prompt-600italic - latin_latin-ext */
@font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 600;
  font-display: fallback;
  src: local('Prompt SemiBold Italic'), local('Prompt-SemiBoldItalic'),
       url('../fonts/prompt-v2-latin_latin-ext-600italic.woff2') format('woff2'),
       url('../fonts/prompt-v2-latin_latin-ext-600italic.woff') format('woff');
}
/* prompt-700 - latin_latin-ext */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
  src: local('Prompt Bold'), local('Prompt-Bold'),
       url('../fonts/prompt-v2-latin_latin-ext-700.woff2') format('woff2'),
       url('../fonts/prompt-v2-latin_latin-ext-700.woff') format('woff');
}
/* prompt-700italic - latin_latin-ext */
@font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 700;
  font-display: fallback;
  src: local('Prompt Bold Italic'), local('Prompt-BoldItalic'),
       url('../fonts/prompt-v2-latin_latin-ext-700italic.woff2') format('woff2'),
       url('../fonts/prompt-v2-latin_latin-ext-700italic.woff') format('woff');
}

} // end @if