/** Day Readings */
	
div.ap-dayreadings div.readingdate {
	margin-bottom: 8px;
}

div.ap-dayreadings div.pericopes {
	margin-top: 12px;
	border-top: 1px solid $grey-lighter;
	display: inline-block;
}

div.ap-dayreadings div.pericopes div.title {
	margin-top: 12px;
}

div.ap-dayreadings div.pericopes div.title span.type {
	font-weight: bold;
}

div.ap-dayreadings div.pericopes div.title span.reference {

}

div.ap-dayreadings div.pericopes div.motto {
	font-style: italic;
	margin-top: 8px;
}

div.ap-dayreadings div.pericopes div.verse div.number {
	float: left;
	display: inline-block;
	width: 10%;
	font-weight: bold;
}

div.ap-dayreadings div.pericopes div.verse div.text {
	float: right;
	display: inline-block;
	width: 90%;
	
}

div.ap-dayreadings div.pericopes div.verse {
	padding-top: 8px;
	clear: both;
}

div.ap-dayreadings div.pericopes div.verselast div {
	padding-bottom: 0;
}