
/* Service calendar */

.ap-service {

    color: $text-dark;
    margin-bottom: 20px;

    a.link {
        color: $text-dark;
    }

    a:hover {
        color: $main-theme-hover;
        text-decoration: none;
    }

    .teaser-body {
        .teaser-date {
            font-style: italic;
        }
    }

    .calendar-sheet {
        min-width: 90px;
        border: 1px solid $grey-light;
        text-align: center; 
        background: $grey-lighter;

        .day, 
        .monthYear {
            padding: 10px;
        }

        h3 {
            color: $main-theme;
            font-weight: bolder;
        }
    }

    // Calendar sheets get a slightly different raise effect
    &.ap-raise-animation:hover .animated-box {
        box-shadow: 5px 5px 8px -1px $dropshadow;
    }
}

// Coloring for services
// NOTE: apollo-list-scss containes coloring classes also used by event
@each $type-name, $bg-color, $text-color, $highlight-color in $many-colors {

    .color-#{""+$type-name} {

        .calendar-sheet {
            &:before {
                border-top: 20px solid $bg-color;
            }

            h3 {
                color: $bg-color;
            }
        }
    }
}