
$calendar-highlight-color: get-highlight-color($main-theme) !default;

.ap-ebkcalendar {
    margin-bottom: 20px;
}

.ap-ebkcalendar .fc-event {
    color: $text-light;
    background-color: $main-theme;
    border: $main-theme;
}

.ap-ebkcalendar .fc-h-event {
    background-color: $main-theme-hover;
    border: $main-theme-hover;
}

.ap-ebkcalendar .calholiday {
}

.ap-ebkcalendar .calmaybeholiday {
    background-color: $calendar-highlight-color;
    border: $calendar-highlight-color;
    color: $text-dark;
}

.ap-ebkcalendar .fc-today {
    background-color: lighten($calendar-highlight-color, 4%);
}

/* Optional rules for the different calendar entry types */
.ap-ebkcalendar .cala-blog {}

.ap-ebkcalendar .cala-event {}

.ap-ebkcalendar .calservice {}
